var installers = new Vue({
    el: '.installers',
    data: {
        installer: ''
    },
    methods: {
        remove: function(installer) {
            this.$set('installer', installer);

            $("#removeInstallerModal").modal('show');
        },
        showInstallationVideo: function() {
            $("#installersVideoModal").modal('show');
        }
    }
});