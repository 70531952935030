var courses = new Vue({
    el: '.courses',
    data: {
        course: {},
        courses: ''
    },
	ready: function() {
		var courseList = JSON.parse(document.getElementById('coursesList').value);
		var courseArrayLength = courseList.length;

		var courseArray = [];

		for(i = 0; i < courseArrayLength; i++) {
			courseArray.push(courseList[i]);
            courseArray[i].list_position = courseArray[i].pivot.list_position;
		}

		this.$set('courses', courseArray);
	},
    computed: {
        postCourses: function() {
            var coursesLength = this.courses.length;

            for(i = 0; i < coursesLength; i++) {
                this.courses[i].list_position = (i + 1);
            }

            return JSON.stringify(this.courses);
        }
    },
    methods: {
        edit: function(course) {
            this.$set('course', course);

            $("#courseEditModal").modal('show');
        },
		handleDrop: function(draggedElement, droppedOnElement) {
            var startingPosition = draggedElement.id;
            var endingPosition = droppedOnElement.id;

            var draggedElementObject = this.courses[startingPosition];

			this.courses.$set(startingPosition, this.courses[endingPosition]);
			this.courses.$set(endingPosition, draggedElementObject);
		}
    }
});

