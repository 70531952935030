var vue = new Vue({
    el: '.system-section',
    data: {
        system: {
            id: 0,
            plan: 0
        },
        subscription: 'free'
    },
    methods: {
        editSubscription: function(system) {
            this.$set('system', system);

            $("#editSubscriptionModal").modal('show');
        },
        removeSubscription: function(system) {
            this.system = system;
            $("#removeSubscriptionModal").modal('show');
        }
    }
});