var capacitators = new Vue({
    el: '.capacitators',
    data: {
        allZones: '',
        selectedZone: '',
        assignedZones: '',
        capacitator: ''
    },
    ready: function() {
        this.$set('allZones', JSON.parse(this.allZones));
        this.$set('assignedZones', JSON.parse(this.assignedZones));

        var allZones = this.allZones;
        var allZonesArrayLength = allZones.length;

        this.$set('allZones', []);

        var finalZones = [];

        for (var i = 0; i < allZonesArrayLength; i++) {
            var item = _.find(this.assignedZones, {'id': allZones[i].id });

            if(item == undefined) {
                finalZones.push(allZones[i]);
            }
        }

        this.$set('allZones', finalZones);

        this.$set('selectedZone', _.first(this.allZones).id);
    },
    computed: {
        postZones: function() {
            return JSON.stringify(this.assignedZones);
        }
    },
    methods: {
        add: function(zone) {
            this.assignedZones.push(zone);
            this.allZones.$remove(zone);
        },
        removeZone: function(zone) {
            this.assignedZones.$remove(zone);
            this.allZones.push(zone);
        }
    }
});