var videos = new Vue({
    el: '.videos',
    data: {
        video: {},
        items: ''
    },
	ready: function() {
        var itemList = JSON.parse(document.getElementById('videosList').value);
        var itemArrayLength = itemList.length;

		var itemArray = [];

        for(i = 0; i < itemArrayLength; i++) {
            itemArray.push(itemList[i]);
            itemArray[i].list_position = itemArray[i].pivot.list_position;
        }

        this.$set('items', itemArray);
    },
	computed: {
		postVideos: function() {
            var itemsLength = this.items.length;

            for(i = 0; i < itemsLength; i++) {
                this.items[i].list_position = (i + 1);
            }
            
            return JSON.stringify(this.items);
        }
	},
    methods: {
        edit: function(video) {
            this.$set('video', video);

            $("#videoEditModal").modal('show');
        },
		delete: function(video) {
			this.$set('video', video);

			$("#deleteVideoModal").modal('show');
		},
		handleDrop: function(draggedElement, droppedOnElement) {
			var startingPosition = draggedElement.id;
            var endingPosition = droppedOnElement.id;

            var draggedElementObject = this.items[startingPosition];

            this.items.$set(startingPosition, this.items[endingPosition]);
            this.items.$set(endingPosition, draggedElementObject);
		}
    }
});
