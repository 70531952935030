var users = new Vue({
    el: '#users',
    data: {
        user: ''
    },
    methods: {
        associate: function(user) {
            this.$set('user', user);
            $("#associateConnectionModal").modal('show');
        },
        deleteUser: function(user) {
            this.$set('user', user);

            $("#deleteUserConfirmation").modal('show');
        }
    }
});