var documents = new Vue({
	el: '#documents',
	ready: function() {
        if(document.getElementById('documentsList') != null) {
            var itemList = JSON.parse(document.getElementById('documentsList').value);
            var itemArrayLength = itemList.length;

            var itemArray = [];

            for(i = 0; i < itemArrayLength; i++) {
                itemArray.push(itemList[i]);
                itemArray[i].list_position = itemArray[i].pivot.list_position;
            }

            this.$set('items', itemArray);
        }
    },
    data: {
		document: '',
		postItems: ''
	},
    computed: {
        postItems: function() {
            var itemsLength = this.items.length;

            for(i = 0; i < itemsLength; i++) {
                this.items[i].list_position = (i + 1);
            }

            return JSON.stringify(this.items);
        }
    },
    methods: {
        handleDrop: function(draggedElement, droppedOnElement) {
            var startingPosition = draggedElement.id;
            var endingPosition = droppedOnElement.id;

            var draggedElementObject = this.items[startingPosition];

            this.items.$set(startingPosition, this.items[endingPosition]);
            this.items.$set(endingPosition, draggedElementObject);
        },
        delete: function(document) {
			this.$set('document', document);

			$('#deleteDocument').modal('show');
		}
    }
});
