var app = new Vue({
    el: '.internalUsers',
    data: {
        users: [],
        newUser: ''
    },
    methods: {
        addUser: function() {
            var user = this.newUser;

            if(user) {
                this.users.push({ username: user });
                this.newUser = '';
            }
        },
        removeUser: function (index) {
          this.users.splice(index, 1);
        },
        save: function() {
            console.log('Save');
        }
    }

});